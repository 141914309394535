
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import sanityClient from '../../client'
import getYouTubeId from 'get-youtube-id'
import YouTube from 'react-youtube'
import BlockContent from "@sanity/block-content-to-react";
import { SanityOnePostData } from "../../types/types";
import { useHistory } from "react-router-dom";
import './onePost.scss'

const serializers = {
  types: {
    youtube: ({ node }) => {
      const { url } = node
      const opts: object = {
        width: '100%',
      };
      const id = getYouTubeId(url)
      if (!id) {
        return <div>No youtube id</div>
      }
      return (<YouTube opts={opts} videoId={id} />)
    }
  }
}

const OnePost: React.FC = () => {

  const history = useHistory();
  const [postData, setPostData] = useState<SanityOnePostData>();
  const { slug } = useParams();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == $slug]{
          title,
          slug,
          mainImage{
            asset->{
              _id,
              url
             }
           },
         body,
        "name": author->name,
        "authorImage": author->image
       }`,
        { slug }
      )
      .then((data) => setPostData(data[0]))
      .catch(console.error);
  }, [slug]);

  if (!postData) return <div>Loading...</div>;

  return (
    <div className='onePostContainer'>
      <div className='borders'>
        <div className='content'>
          <div onClick={history.goBack} className='backButton'>Go back</div>
          <div className='postDataTitle'>
            {postData.title}
          </div>
          <BlockContent
            blocks={postData.body}
            projectId={sanityClient.clientConfig.projectId}
            dataset={sanityClient.clientConfig.dataset}
            className='blockContent'
            serializers={serializers}
          />
        </div>
      </div>
    </div>
  );
}

export default OnePost;