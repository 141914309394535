import React from 'react';
import Particles from 'react-particles-js';

const Background: React.FC = () => {
    let particles = 25
    let width = window.innerWidth;
    if (width < 1268) {
        particles = 20
    }
    return (
        <Particles
            height="96vh"
            params={{
                "particles": {
                    "number": {
                        "value": particles,
                        "density": {
                            "enable": true,
                            "value_area": 1000
                        }
                    },
                    "color": {
                        "value": "#EAE2B7"
                    },
                    "opacity": {
                        "value": 0.4,
                        "random": false,
                        "anim": {
                            "enable": false,
                            "speed": 5
                        }
                    },
                    "size": {
                        "value": 4,
                        "random": true,
                        "anim": {
                            "enable": false,
                            "speed": 10,
                            "size_min": 0.1,
                            "sync": false
                        }
                    },
                    "move": {
                        "enable": true,
                        "speed": 1.7,
                        "direction": "none",
                        "random": false,
                        "straight": false,
                        "out_mode": "out",
                        "bounce": false,
                        "attract": {
                            "enable": false,
                            "rotateX": 600,
                            "rotateY": 1200
                        }
                    },
                    "line_linked": {
                        "enable": true,
                        "distance": 250,
                        "color": "#ffffff",
                        "opacity": 0.5,
                        "width": 1
                    }
                },
                "interactivity": {
                    "detect_on": "canvas",
                    "events": {
                        "onhover": {
                            "enable": true,
                            "mode": "grab"
                        },
                        "onclick": {
                            "enable": true,
                            "mode": "push"
                        },
                        "resize": true
                    },
                    "modes": {
                        "grab": {
                            "distance": 400,
                            "line_linked": {
                                "opacity": 1
                            }
                        },
                    }
                },
                "retina_detect": true
            }} />
    );
}

export default Background;
