import React from 'react';
import { BrowserRouter, Route } from "react-router-dom";
import App from '../compontents/app/App';
import OnePost from '../compontents/sanityPosts/OnePost'

const Router: React.FC = () => {
    return (
        <BrowserRouter>
            <div>
                <Route component={App} path='/' exact />
                <Route component={OnePost} path='/:slug' />
            </div>
        </BrowserRouter>
    )
}

export default Router;