import React, { useEffect, useState } from "react";
import sanityClient from "../../client";
import "./sanityPost.scss";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import { SanityAllPostsData } from "../../types/types";

const AllPosts: React.FC = () => {
  const [allPostsData, setAllPosts] = useState<SanityAllPostsData[]>();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"] | order(publishedAt desc) {
                title,
                slug,
                publishedAt,
                mainImage{
                  asset->{
                  _id,
                  url
                }
              }
            }`
      )
      .then((data) => {
        setAllPosts(data);
      })
      .catch(console.error);
  }, []);
  return (
    <div className="secondContainer">
      <div className="secondOuterBorder"></div>
      <Fade top cascade duration={2000}>
        <div className="projectHighlights">
          <div className="projectTitle">Project highlights</div>
        </div>
      </Fade>
      <div className="gallery">
        {allPostsData &&
          allPostsData.map((post, index) => (
            <Link to={"/" + post.slug.current} key={post.slug.current}>
              <span key={index}>
                <div className="image">
                  <div className="svg-overflow">
                    <img src="assets/wave.svg" id="wave-svg" alt="wave" />
                  </div>
                  <img
                    src={post.mainImage.asset.url}
                    className="responsive"
                    alt={post.slug.current}
                  />
                </div>
                <span>
                  <div className="itemTitle">{post.title}</div>
                  {/* <div className="itemText">{post.body} */}
                  {/* </div> */}
                </span>
              </span>
            </Link>
          ))}
      </div>
    </div>
  );
};

export default AllPosts;
