import React from "react";
import "./App.css";
import FirstPage from "../firstPage/FirstPage";
import ThirdPage from "../thirdPage/thirdPage";
import Helmet from "react-helmet";
import AllPosts from "../sanityPosts/AllPosts";

const App: React.FC = () => {
  return (
    <div>
      <Helmet>
        <meta itemProp="name" content="Wictor Zhao" />
        <meta
          itemProp="description"
          content="I am Wictor Zhao. Welcome to my portfolio web site.
          I am a computer science student at NTNU, specializing in software development."
        />
      </Helmet>
      <FirstPage />
      <AllPosts />
      <ThirdPage />
    </div>
  );
};

export default App;
