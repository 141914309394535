import React, { useEffect, useRef, useState } from "react";
import "./thirdPage.scss";
import Fade from "react-reveal/Fade";
// @ts-ignore
import { AwesomeButton, AwesomeButtonSocial } from "react-awesome-button";
import "react-awesome-button/dist/styles.css";
import sanityClient from "../../client";
import BlockContent from "@sanity/block-content-to-react";
import { SanityAboutMe } from "../../types/types";

const ThirdPage: React.FC = () => {
  const [aboutMe, setAboutMe] = useState<SanityAboutMe>();
  const executedRef = useRef(false);

  useEffect(() => {
    if (executedRef.current) {
      return;
    }
    sanityClient
      .fetch(
        `*[_type == "author"] {
              name,
              slug,
              bio,
              image{
                asset->{
                _id,
                url
              },
            }
          }`
      )
      .then((data) => {
        setAboutMe(data[0]);
      })
      .catch(console.error);
    executedRef.current = true;
  }, []);

  return (
    <div className="thirdContainer">
      <Fade top cascade>
        <div className="aboutMe">
          <div className="aboutMeTitle">Who am I?</div>
        </div>
      </Fade>
      <div className="thirdOuterBorder" />
      <Fade bottom cascade>
        <div className="aboutMeContainer">
          <div className="aboutMeImage">
            <img src="assets/profil.jpeg" className="cv-responsive" alt="wz" />
          </div>

          <div className="aboutMeItem">
            <div className="aboutMeText">
              {aboutMe?.bio ? (
                <BlockContent blocks={aboutMe.bio} />
              ) : (
                <p>Loading</p>
              )}
            </div>
            <br />
            <AwesomeButton
              rel="noopener"
              href="assets/CV.pdf"
              target="_blank"
              type="secondary"
              ripple
              size="large"
            >
              View resume
            </AwesomeButton>
            <div className="social">
              <h2>Connect with me!</h2>
              <div className="social-button">
                <AwesomeButtonSocial
                  rel="noopener"
                  href="https://www.linkedin.com/in/wictorz/"
                  target="_blank"
                  type="linkedin"
                  size="large"
                >
                  LinkedIn
                </AwesomeButtonSocial>
                <AwesomeButtonSocial
                  rel="noopener"
                  href="https://github.com/rotciw"
                  target="_blank"
                  type="github"
                  size="large"
                >
                  Github
                </AwesomeButtonSocial>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default ThirdPage;
