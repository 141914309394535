import React from 'react';
import './firstPage.scss';
import Background from '../background/Background';
import Typed from 'react-typed';

interface IProps {

}

interface IState {
    isHide: boolean,
}

class FirstPage extends React.Component<IProps, IState>{
    private scrollToRef = React.createRef<HTMLDivElement>();
    constructor(props: IProps) {
        super(props)
        this.state = {
            isHide: false
        }
    }

    scroll() {
        if (this.scrollToRef && this.scrollToRef.current) {
            this.scrollToRef.current.scrollIntoView();
        }
    }

    render() {
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        let vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        window.addEventListener('resize', () => {
            // We execute the same script as before
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        });
        let classHide = this.state.isHide ? 'hide' : '';
        return (
            <div className="container">
                <div className="outerBorder" />
                <div className={'arrow animation ' + classHide}
                    onClick={() => {
                        if (this.scrollToRef && this.scrollToRef.current) {
                            this.scrollToRef.current.scrollIntoView();
                        }
                    }}
                >
                    Scroll
                    <img src="assets/angle-down-solid.svg" alt="arrow-down" />
                </div>
                <div className="innerContainer">
                    <Background />
                </div>
                <div className="center">
                    <div className="center-text">
                        <Typed
                            strings={['Hello world!',
                                'I\'m Wictor',
                                'Welcome to my space!']}
                            typeSpeed={40}
                            backSpeed={40}
                            startDelay={500}
                            loop
                        >
                        </Typed>
                    </div>
                </div>
                <div className="bottom" ref={this.scrollToRef} >
                </div>
            </div>
        );
    }
}

export default FirstPage;
