import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import './fonts/Karla-Regular.ttf';
import './fonts/Karla-Bold.ttf';
import firebase from 'firebase/app';
import 'firebase/analytics';
import Router from './router/Router';

const firebaseConfig = {
    apiKey: "AIzaSyCx-qFa_kVVtCrbmte4e7B8ugrJ3xF40Js",
    authDomain: "portfolio-32a7d.firebaseapp.com",
    databaseURL: "https://portfolio-32a7d.firebaseio.com",
    projectId: "portfolio-32a7d",
    storageBucket: "portfolio-32a7d.appspot.com",
    messagingSenderId: "404857899233",
    appId: "1:404857899233:web:8a9a5416c559b4e724cfa0",
    measurementId: "G-G4TLQJMZ7D"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

ReactDOM.render(<Router />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
